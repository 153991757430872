body, html {
    font-family: 'Nunito', sans-serif;
}

.admin-access-container {
    height: 100vh;
    width: 100vw;
}

.admin-access-form-container {
    position: absolute;
    top: 0;
    left: 50vw;
    background-color: transparent;
    width: 50vw;
    height: 100vh;
}




.admin-access-welcome-message {
    margin-top: 10vh;
    margin-left: 0vw;
    font-size: 2em;
    color: rgb(245, 244, 228);
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}

.admin-access-update-form {
    background-color: rgb(22, 23, 29, .5);
    width: 40vw;
    height: 60vh;
    margin-top: 5vh;
    margin-left: 0vw;
    font-family: 'Nunito', sans-serif; 
    padding: 2vw; 
    padding-bottom: 5vh;
    border-radius: 25px;
}
.admin-access-password-form {
    background-color: rgb(22, 23, 29, .5);
    width: 30vw;
    height: auto;
    margin-top: 1vh;
    margin-left: 0vw;
    font-family: 'Nunito', sans-serif;
    padding: 4vw;
    border-radius: 25px;
    
}

.admin-access-name-input, 
.admin-access-project-input, 
.admin-access-password-input,
.admin-access-organization-input {
    width: 25vw;
    height: 5vh;
    border-radius: 25px;
    border: none;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 1.5vw;
    color: rgb(22, 23, 29);
    font-size: 10pt;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(245, 244, 228);
}

.admin-access-date-picker {
    width: 100px;
    height: 5vh;
    border-radius: 25px;
    border: none;
    margin-bottom: 2vh;
    text-align: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(245, 244, 228);
}

.admin-access-update-description {
    width: 35vw;
    height: 20vh;
    border-radius: 25px;
    border: none;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 1.5vw;
    padding-top: 2vh;
    resize: none;
    font-size: 10pt;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(245, 244, 228);
}

.admin-access-form-submit {
    margin-top: 3vh;
    width: 6rem;
    height: 3rem;
    border-radius: 25px;
    border: none;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(67, 134, 149);
    color: rgb(245, 244, 228);
    font-weight: bold;
}

.admin-access-form-submit:hover {
    background-color: rgb(67, 134, 149, .5);
    color: rgb(245, 244, 228, .5);
    cursor: pointer;
}

.admin-add-remove-button {
    margin-top: 3vh;
    width: auto;
    height: 50px;
    border-radius: 25px;
    border: none;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(67, 134, 149);
    color: rgb(245, 244, 228);
    font-weight: bold;
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;

}

.admin-update-name {

    font-size: 10px;
}

.admin-transfer-ownership {
    background-color: transparent;
    border: solid 1px #F5F4E4;
    border-radius: 25px;
    color: rgb(145, 67, 63);


}


/* Responsiveness */

@media screen and (max-width: 800px) {
    .admin-access-form-container {
        position: absolute;
        top: 0;
        left: 10vw;
        background-color: transparent;
        width: 100vw;
        height: 100vh;
    }

    .admin-access-name-input, 
    .admin-access-project-input, 
    .admin-access-password-input,
    .admin-access-organization-input {
        width: 50vw;
    }

    .admin-access-update-description {
        width: 55vw;
    }

    .admin-access-update-form,
    .admin-access-password-form  {
        width: 60vw;
        padding: 5vw;
        padding-bottom: 5vh;
        display: flex;
    }


}



.css-gcwvw8-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0
}

