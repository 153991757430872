body, html {
    font-family: 'Nunito', sans-serif;
}

.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;

}

.navbar-logo {
    width: 15vw;
    padding: 2vw;
}

@media only screen and (max-width: 800px) {
    .navbar-logo {
        width: 11rem;
        padding: 2vw;
        
    }
}
.navbar-logo-button-wrapper {
    background-color: transparent;
    border: none;
    cursor: pointer;
}



