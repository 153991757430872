
.admin-updates-access-container {
    height: 100vh;
    width: 100vw;
}

.admin-updates-access-form-container {
    position: absolute;
    top: 0;
    left: 50vw;
    background-color: transparent;
    width: 50vw;
    height: 100vh;
}

.admin-updates-access-welcome-message {
    margin-top: 10vh;
    margin-left: 0vw;
    font-size: 2em;
    color: rgb(245, 244, 228);
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}


.admin-updates-access-update-form {
    background-color: rgb(22, 23, 29, .5);
    width: 40vw;
    height: 60vh;
    margin-top: 5vh;
    margin-left: 0vw;
    font-family: 'Nunito', sans-serif; 
    padding: 2vw; 
    padding-bottom: 5vh;
    border-radius: 25px;
}

.admin-updates-add-remove-button {
    width: 3vw;
    height: 5vh;
    border-radius: 25px;
    border: none;
    background-color: #857A40;
    color: #F5F4E4;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 1em;
    margin-top: 1vh;
    margin-left: 0vw;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: auto;
}

.admin-updates-add-remove-member {
    width: 30vw;
    height: 5vh;
    border-radius: 25px;
    border: none;
    background-color: #438695;
    color: #F5F4E4;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 1em;
    margin-top: 1vh;
    margin-left: 0vw;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: auto;
}

.admin-access-update-form {
    display: flex;
    flex-direction: column;
}

.admin-sensitive-buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 5vh;
    border: solid 1px rgb(145, 67, 63);
    padding: 1vw;
    border-radius: 25px;

}

.input-input {
    margin-top: 50px;
    width: 30vw;
    height: 5vh;
    border-radius: 25px;
    border: none;
    background-color: rgb(245, 244, 228);
    color: rgb(22, 23, 29);
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 1em;
    margin-top: 1vh;
    margin-left: 0vw;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: auto;
}

.button-button {
    /* this is a button */
    width: 10vw;
    height: 5vh;
    border-radius: 25px;
    border: none;
    background-color: #438695;
    color: #F5F4E4;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 1em;
    margin-top: 1vh;
    margin-left: 0vw;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}