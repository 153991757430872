body, html {
    font-family: 'JetBrains Mono', monospace;
}

.team-access-container {
    height: 100vh;
    width: 100vw;
}

.team-access-form-container {
    position: absolute;
    top: 0;
    left: 50vw;
    background-color: transparent;
    width: 50vw;
    margin-top: 15vh;
}

.team-access-welcome-message {
    margin-left: 0vw;
    width: 60vw;
    font-size: 1.5em;
    color: rgb(245, 244, 228);
    font-family: 'JetBrains Mono', monospace;
    font-weight: bold;
}

.team-access-organization-name {
    font-size: .5em;
    color: rgb(245, 244, 228, .5);
}

.team-access-update-form {
    background-color: rgb(22, 23, 29, .9);
    width: 40vw;
    height: auto;
    margin-left: 0vw;
    font-family: 'JetBrains Mono', monospace; 
    padding: 2vw; 
    padding-bottom: 5vh;
    border-radius: 15px;
}

.team-access-update-form-post-submit {
    background-color: rgb(22, 23, 29, .5);
    width: 20vw;
    height: auto;
    margin-top: 5vh;
    margin-left: 0vw;
    font-family: 'JetBrains Mono', monospace;
    padding: 7vw; 
    border-radius: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;
}


.team-access-password-form {
    background-color: rgb(22, 23, 29, .5);
    width: 30vw;
    height: auto;
    margin-top: 1vh;
    margin-left: 0vw;
    font-family: 'JetBrains Mono', monospace;
    padding: 5vw;
    border-radius: 15px;
    
}

.team-access-name-input, 
.team-access-project-input, 
.team-access-password-input,
.team-access-organization-input {
    width: 25vw;
    height: 5vh;
    border-radius: 10px;
    border: none;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 1rem;
    color: rgb(22, 23, 29);
    font-size: .9rem;
    font-family: 'JetBrains Mono', monospace;
    background-color: rgb(245, 244, 228);
}

.team-access-date-picker {
    width: 100px;
    height: 5vh;
    border-radius: 10px;
    border: none;
    margin-bottom: 2vh;
    text-align: center;
    font-size: .7rem;
    font-weight: bold;
    font-family: 'JetBrains Mono', monospace;
    background-color: rgb(245, 244, 228);
}

.team-access-update-description {
    width: 35vw;
    height: 20vh;
    border-radius: 15px;
    border: none;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 1rem;
    padding-top: 2vh;
    resize: none;
    font-size: .9rem;
    font-family: 'JetBrains Mono', monospace;
    background-color: rgb(245, 244, 228);
}

.team-access-form-submit {
    margin-top: 3vh;
    width: 6rem;
    height: 3rem;
    border-radius: 25px;
    border: none;
    font-family: 'JetBrains Mono', monospace;
    background-color: rgb(145, 67, 63);
    color: rgb(245, 244, 228);
    font-weight: bold;
    font-size: .8rem;
}

.team-access-form-submit-post-submit-new-update {
    margin: 2vw;
    width: 150px;
    height: 7vh;
    border-radius: 25px;
    border: none;
    font-family: 'JetBrains Mono', monospace;
    background-color: rgb(145, 67, 63);
    color: rgb(245, 244, 228);
    font-weight: bold;
    font-size: 9pt;
}

.team-access-form-submit-post-submit-home {
    margin: 2vw;
    width: 150px;
    height: 7vh;
    border-radius: 25px;
    border: none;
    font-family: 'JetBrains Mono', monospace;
    background-color: rgb(67, 134, 149);
    color: rgb(245, 244, 228);
    font-weight: bold;
    font-size: 9pt;
}

.team-access-form-submit:hover, 
.team-access-form-submit-post-submit-new-update:hover,
.team-access-form-submit-post-submit-home:hover {
    background-color: rgb(245, 244, 228);
    color: rgb(22, 23, 29);
    cursor: pointer;
}

.team-access-form-submit:active, 
.team-access-form-submit-post-submit-new-update:active,
.team-access-form-submit-post-submit-home:active {
    background-color: rgb(245, 244, 228, .5);

}

.teams-access-no-login-text {
    color: rgb(245, 244, 228, .5);
    font-size: 12pt;
}


/* Responsiveness */

@media screen and (max-width: 800px) {
    .team-access-form-container {
        position: absolute;
        top: 0;
        left: 10vw;
        background-color: transparent;
        width: 100vw;
        height: 100vh;
    }

    .team-access-name-input, 
    .team-access-project-input, 
    .team-access-password-input,
    .team-access-organization-input {
        width: 50vw;
        padding-left: 1rem;
    }

    .team-access-update-description {
        width: 55vw;
    }

    .team-access-form-submit-post-submit-home,
    .team-access-form-submit-post-submit-new-update {
        margin: 5vw;
    }

    .team-access-update-form,
    .team-access-password-form,
    .team-access-update-form-post-submit  {
        width: 60vw;
        height: auto;
        padding: 5vw;
        padding-bottom: 5vh;
    }


}