
.homepage-font {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    color: rgb(243, 241, 211);
    text-decoration: none;
}

.homepage-container {
    height: 100vh;
    background-color: rgb(42, 42, 46);
}

.homepage-buttons-container {
    background-color: transparent;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row; 
    
}
            @media only screen and (max-width: 800px) {
                .homepage-buttons-container {
                    flex-direction: column;
                    
                }
            }

.homepage-admin-button, .homepage-dashboard-button, .homepage-members-button {
    background-color: rgb(22, 23, 29, .25);
    padding: 7vw;
    width: 7vw;
    margin: auto;
    margin-top: 1rem;
    border-radius: 25px; 
}
.homepage-admin-button:hover, .homepage-dashboard-button:hover, .homepage-members-button:hover {
    background-color: rgb(22, 23, 29);
}
.homepage-admin-button:active, .homepage-dashboard-button:active, .homepage-members-button:active {
    background-color: rgb(22, 23, 29, .5);
}

.homepage-buttons-wrapper {
    text-align: center;
    margin: 2vw;
}



