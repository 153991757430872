body, html {
    font-family: 'Nunito', sans-serif;
}

.dashboard-access-container {
    margin-top: 15vh;
    height: auto;
    width: 100vw;

}

.dashboard-access-container-password {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
}

.dashboard-access-form-container {
    position: absolute;
    top: 0;
    left: 50vw;
    background-color: transparent;
    width: 50vw;
    height: 100vh;
}


.dashboard-access-welcome-message {
    margin-top: 10vh;
    margin-left: 0vw;
    font-size: 2em;
    color: rgb(245, 244, 228);
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}

.dashboard-access-update-form {
    background-color: rgb(22, 23, 29, .9);
    width: 40vw;
    height: 60vh;
    margin-top: 5vh;
    margin-left: 0vw;
    font-family: 'Nunito', sans-serif; 
    padding: 2vw; 
    padding-bottom: 5vh;
    border-radius: 25px;
}
.dashboard-access-password-form {
    background-color: rgb(22, 23, 29, .9);
    width: 30vw;
    height: auto;
    margin-top: 1vh;
    margin-left: 0vw;
    font-family: 'Nunito', sans-serif;
    padding: 4vw;
    border-radius: 25px;
    
}

.dashboard-access-name-input, 
.dashboard-access-project-input, 
.dashboard-access-password-input,
.dashboard-access-organization-input {
    width: 25vw;
    height: 5vh;
    border-radius: 25px;
    border: none;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 1.5vw;
    color: rgb(22, 23, 29);
    font-size: 10pt;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(245, 244, 228);
}


.dashboard-access-form-submit {
    margin-top: 3vh;
    width: 6rem;
    height: 3rem;
    border-radius: 25px;
    border: none;
    font-family: 'Nunito', sans-serif;
    background-color: rgb(67, 134, 149);
    color: rgb(245, 244, 228);
    font-weight: bold;
}

.dashboard-access-form-submit:hover {
    background-color: rgb(67, 134, 149, .5);
    color: rgb(245, 244, 228, .5);
    cursor: pointer;
}


.dashboard-access-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Responsiveness */

@media screen and (max-width: 800px) {
    .dashboard-access-form-container {
        margin-top: 10vh;
        margin-left: 5vw;
        background-color: transparent;
        width: 100vw;
        height: 100vh;
    }

    .dashboard-access-name-input, 
    .dashboard-access-project-input, 
    .dashboard-access-password-input,
    .dashboard-access-organization-input {
        width: 50vw;
    }

    .dashboard-access-password-form  {
        width: 70vw;
        padding: 5vw;
        padding-bottom: 5vh;
    }
}


/* Responsiveness */

@media screen and (max-width: 800px) {
    .dashboard-access-form-container {
        position: absolute;
        top: 0;
        left: 5vw;
        background-color: transparent;
        width: 100vw;
        height: 100vh;
    }

    .dashboard-access-container {
        margin-top: 10vh;
        
    }

    .dashboard-access-name-input, 
    .dashboard-access-project-input, 
    .dashboard-access-password-input,
    .dashboard-access-organization-input {
        width: 50vw;
    }

    .dashboard-access-update-description {
        width: 65vw;
    }

    .dashboard-access-update-form,
    .dashboard-access-password-form  {
        width: 60vw;
        padding: 5vw;
        padding-bottom: 5vh;
    }
}